import { Brand, Environment } from './typings/shared';

export const BRAND = window.appSettings.brand as Brand;

export const ENVIRONMENT = window.appSettings.environment as Environment;

export const GA_ID = window.appSettings.gaId;

export const NIELSEN_ID = window.appSettings.nielsenId;

export const CMD_URL = window.appSettings.cmdUrl;

export const PLAYER_VERSION = '3.38.0_B13418';

export const CMD_VERSION = '6.7.0';

export const STOFA_CUSTOMER_SUPPORT_NUMBER = '88 30 30 30';

export const NORLYS_CUSTOMER_SUPPORT_NUMBER = '70 11 40 40';
